import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Banner.styles';
import ReactMarkdown from 'react-markdown';
import sa from '../../../styles/Components/SimpleAnimation';
import InView from '../../Shared/InView/InView';
import { getHexValuesInString } from '../../../util/colors';
import createClasslist from '../../../util/createClasslist';
import Picture from '../../Shared/Picture';

const Banner = ({
  borderColor = '',
  link = {},
  title = '',
  image = {},
  fadeImageToBackground = true,
  backgroundColor = '',
  textColor = '',
  imageOverlay = null,
  className = '',
  ...props
}) => {
  return (
    <InView>
      <Styles
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        textColor={textColor}
        linkButtonColor={getHexValuesInString(link?.buttonAndTextColors)[0]}
        linkTextColor={getHexValuesInString(link?.buttonAndTextColors)[1]}
        linkBorderColor={borderColor}
        className={createClasslist('banner', className)}
        {...props}
      >
        <div className='banner__image-title-container'>
          <div className='banner__image'>
            {image?.src && (
              <>
                <Picture
                  isResponsive
                  src={image?.src}
                  alt={image?.alt}
                  sources={image?.sources}
                />
                {fadeImageToBackground && (
                  <div className='banner-image__gradient' />
                )}
                <div className='banner__image__overlay'>{imageOverlay}</div>
              </>
            )}
          </div>
          {title && (
            <div className='banner__title'>
              <ReactMarkdown source={title} />
            </div>
          )}
        </div>
        {link?.link && link?.linkText && (
          <sa.div
            className='banner__link'
            slideStrength={400}
            shouldFade={false}
            slideDirection='left'
            delay={0.3}
          >
            <a href={link?.link} target='_blank' rel='noopener noreferrer'>
              {link?.linkText}
            </a>
          </sa.div>
        )}
      </Styles>
    </InView>
  );
};

Banner.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    sources: PropTypes.shape({
      media: PropTypes.string,
      params: PropTypes.string,
      srcSet: PropTypes.string,
    }),
  }),
};

export default Banner;
