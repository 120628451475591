import styled, { css } from 'styled-components'

export const DisclaimerLinkStyles = styled.section`
  .link-styles a {
    color: #c5e9fe;
    &:hover{
        color: ${props => props.theme.colors.vmfGreen2};
      }
    &:focus {
      color: ${props => props.theme.colors.vmfGreen2};
    }
  }
`

const bannerTextStyles = (fontSizeRems, lineHeightRem) => css`
  font-size: ${fontSizeRems}rem;
  font-family: 'neue-haas-grotesk-display';
  line-height: ${lineHeightRem}rem;
  
  * {
    font-size: ${fontSizeRems}rem;
    font-family: 'neue-haas-grotesk-display';
    line-height: ${lineHeightRem}rem;
  }
`

export default styled.section`
  .home-banner {

    .banner__title {
      ${bannerTextStyles(2, 2.2)}

      @media (max-width: 860px) {
        ${bannerTextStyles(1.5, 1.9)}
      }

      @media (max-width: ${props => props.theme.breakpoints.tablet}) {
        ${bannerTextStyles(1.2, 1.29375)}
        padding-left: 1rem;
      }

      @media (max-width: 400px) {
        ${bannerTextStyles(1, 1.29375)}
        width: 100px;
      }
    }

    .banner__link {
      font-family: 'neue-haas-grotesk-display';
      * {
        font-family: 'neue-haas-grotesk-display';
      }
      @media (max-width: ${props => props.theme.breakpoints.tablet}) {
        flex-basis: 120px;
      }

    }    
  }
`